<template>
  <div v-if="rounds.length" class="bracket-slider">
    <div class="slider-container">
      <div ref="sliderWrapper" class="slider-wrapper">
        <div
          v-for="(round, index) in filteredRounds"
          :key="index"
          :class="{ empty: !round.name }"
          class="slide"
        >
          <slot name="round" :round="round">
            <span class="name">
              {{ round.name }}
              <pvp-tooltip v-if="round.description" class="info">
                <icon name="info" inline />
                <!-- eslint-disable vue/no-v-html -->
                <div
                  slot="tooltip"
                  class="info-tooltip"
                  v-html="round.description"
                />
              </pvp-tooltip>
            </span>
            <div class="description">
              <template
                v-if="round.matchesInfo && round.matchesInfo.text"
              >
                {{ round.matchesInfo.text }}
              </template>
              <template v-else-if="round.gameMap">
                {{ round.gameMap.name }}
              </template>
              <template v-else>
                {{ $dt(round.tsStartTime, 'dd_MM_HH_mm') }}
              </template>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <button
      v-if="!prev.disabled"
      class="slider-nav prev"
      @click="onNavClick(-1)"
    >
      <icon name="arrow-left" />
    </button>
    <button
      v-if="!next.disabled"
      class="slider-nav next"
      @click="onNavClick(1)"
    >
      <icon name="arrow-right" />
    </button>
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'BracketSlider',
  components: {
    Icon,
  },
  props: {
    rounds: {
      type: Array,
      required: true,
    },
    leftScroll: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hammer: null,
      prev: {
        disabled: true,
      },
      next: {
        disabled: false,
      },
    };
  },
  computed: {
    filteredRounds() {
      return (
        this.rounds?.map((round, index) => ({
          ...round,
          prevRoundId: this.rounds[index - 1]?.id,
          nextRoundId: this.rounds[index + 1]?.id,
        })) || []
      );
    },
  },
  watch: {
    leftScroll: {
      handler: 'updateNavButtons',
      immediate: true,
    },
  },
  mounted() {
    const wrapper = this.$refs.sliderWrapper;
    this.hammer = new Hammer(wrapper);
    this.hammer
      .get('pan')
      .set({ direction: Hammer.DIRECTION_HORIZONTAL });

    let pos = 0;
    this.hammer.on('panstart', () => {
      pos = wrapper.scrollLeft;
    });

    this.hammer.on('panleft panright', (ev) => {
      if (Math.abs(ev.deltaY) < 30) {
        wrapper.scrollLeft = pos - ev.deltaX;
        this.updatePosition(wrapper.scrollLeft);
      }
    });
    this.updateNavButtons();
  },
  beforeDestroy() {
    this.hammer.destroy();
  },
  methods: {
    onNavClick(direction) {
      const wrapper = this.$refs.sliderWrapper;
      const { children } = wrapper;
      const offsets = [].map.call(
        children,
        (slide) => slide.offsetLeft,
      );
      const currentPos = wrapper.scrollLeft;
      const slideWidth = children[0].offsetWidth;
      const activeIndex =
        offsets.findIndex((item) => item >= currentPos) + direction;
      const maxVisibleIndex =
        activeIndex + Math.ceil(wrapper.offsetWidth / slideWidth);
      this.$emit(
        'scroll',
        offsets[activeIndex],
        activeIndex,
        maxVisibleIndex,
      );
    },

    updatePosition(currentPos) {
      const wrapper = this.$refs.sliderWrapper;
      const { children } = wrapper;
      const offsets = [].map.call(
        children,
        (slide) => slide.offsetLeft,
      );
      const slideWidth = children[0].offsetWidth;
      const activeIndex = offsets.findIndex(
        (item) => item + slideWidth >= currentPos,
      );
      wrapper.scrollLeft = currentPos;
      const maxVisibleIndex =
        activeIndex + Math.ceil(wrapper.offsetWidth / slideWidth);
      this.$emit(
        'scroll',
        wrapper.scrollLeft,
        activeIndex,
        maxVisibleIndex,
      );
    },

    updateNavButtons() {
      const wrapper = this.$refs.sliderWrapper;
      if (wrapper) {
        this.updatePosition(this.leftScroll);
        const { scrollLeft, scrollWidth, clientWidth } = wrapper;
        this.prev.disabled = !scrollLeft;
        this.next.disabled = scrollLeft === scrollWidth - clientWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bracket-slider {
  position: relative;
}

.slider {
  &-container {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: stretch;
    @include min-tablet() {
      margin: 0 30px;
    }
    @include max-tablet() {
      margin: 0 10px;
    }
  }

  &-wrapper {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    padding-bottom: em(30px);
    margin-bottom: em(-30px);
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &-nav {
    position: absolute;
    width: 28px;
    z-index: 1;
    outline: none;
    border: none;
    border-radius: 6px;
    height: 100%;
    background: #333335;
    top: 0;
    @include max-tablet() {
      display: none;
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }

    .icon {
      max-width: 100%;
      max-height: 100%;
      color: #f5f5f7;
    }
  }
}

.slide {
  display: inline-block;
  width: em(200px);
  flex-shrink: 0;
  padding: 10px 12px;
  border-radius: 6px;

  &:not(.empty) {
    background-color: #333335;
  }

  & + .slide {
    margin-left: em(30px);
  }

  .name {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.25;

    .info {
      line-height: 1;
      margin-left: 0.4em;
      color: rgba(white, 0.8);

      &:hover {
        color: white;
      }
    }
  }

  .description {
    margin-top: 4px;
    display: block;
    font-size: em(13px);
    color: rgba(white, 0.43);
    line-height: 1.25;
  }
}

.info-tooltip {
  ::v-deep p {
    margin: 5px 0;
    font-size: 13px;
  }
}

@media print {
  .slide {
    color: #cccccc;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: #121213 !important;
  }
}
</style>
