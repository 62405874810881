<template>
  <box v-if="currentTeam" class="next-match">
    <div class="info">
      <template v-if="isTffa">
        <BaseTeamLink
          :hash="currentTeam.hash"
          class="info-vs active"
        />
        <div class="info-match">
          <div class="round-name">
            {{ match.roundName }}, {{ match.name }}
          </div>
          <div class="date">
            {{ $dt(match.tsStartTime, 'dd_MMM_HH_mm') }}
          </div>
        </div>
      </template>

      <template v-if="system === 'over'">
        <div class="info-vs">
          <BaseTeamLink
            v-if="team1.id"
            :hash="team1.hash"
            :class="{ active: team1.isActive }"
            :is-reversed="true"
          />
          <span v-else class="team tbd">TBD</span>
          <div class="divider">
            <template v-if="match.idWinner">
              <span class="points" :class="team1.status">
                {{ team1.points }}
              </span>
              :
              <span class="points" :class="team2.status">
                {{ team2.points }}
              </span>
            </template>
            <span v-else class="vs">VS</span>
          </div>
          <BaseTeamLink
            v-if="team2.id"
            :hash="team2.hash"
            :class="{ active: team2.isActive }"
          />
          <span v-else class="team tbd">TBD</span>
        </div>
        <div class="info-match">
          <div class="date">
            {{ $dt(match.tsStartTime, 'dd_MMM_HH_mm') }}
          </div>
          <div class="round-name">{{ match.roundName }}</div>
        </div>
      </template>
    </div>

    <BaseLink
      v-if="isLinkVisible"
      :to="{ name: 'match', params: { id: match.id } }"
      icon-right="arrow-right"
    >
      {{ $t('global.more') }}
    </BaseLink>
  </box>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';

import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  name: 'PlayerNextMatch',
  components: {
    BaseLink,
    BaseTeamLink,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    rounds: {
      type: Array,
      required: true,
    },
    system: {
      type: String,
      default: 'over',
    },
  },
  data: () => ({
    currentTeam: '',
    currentMatchId: null,
  }),
  computed: {
    ...mapState('tournaments', ['currentUserTournaments']),
    team1() {
      return this.formatTeam(1);
    },
    team2() {
      return this.formatTeam(2);
    },
    isTffa() {
      return this.system === 'tffa';
    },
    isLinkVisible() {
      return this.isTffa || (this.team1.id && this.team2.id);
    },
    match() {
      const findMatch = (round) => {
        const searchScheme = {
          tffa: ({ id }) => id === this.currentMatchId,
          over: ({ team1, team2 }) =>
            [team1?.hash, team2?.hash].includes(
              this.currentTeam.hash,
            ),
        }[this.system];

        const match = round.matches.find(searchScheme);

        if (match) {
          match.roundName = round.name;
        }
        return match;
      };

      return this.rounds.reduce(
        (match, round) => findMatch(round) || match,
        {},
      );
    },
  },
  watch: {
    currentUserTournaments: {
      async handler(userTournaments) {
        const currentTeam = userTournaments[this.id]?.team;
        if (currentTeam && this.isTffa) {
          const data = await this.fetchSearchByName(currentTeam.name);
          const matchesList =
            data?.items?.TeamTitle?.find(
              ({ teamHash }) => teamHash === currentTeam?.hash,
            )?.matchesList || [];
          this.currentMatchId = _.last(matchesList);
          this.currentTeam = this.currentMatchId ? currentTeam : null;
        } else {
          this.currentTeam = currentTeam;
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatTeam(side) {
      const team = this.match[`team${side}`];

      if (!team?.hash || team.hash === this.freeSlot.hash) {
        return {
          status: 'lose',
          points: 0,
        };
      }

      const { technicalDefeatHashList = [] } = this.match;

      const defeatText = this.$t('matches.tech_shortDefeat');
      const points = technicalDefeatHashList.includes(team.hash)
        ? defeatText
        : this.match[`pointsTeam${side}`];

      const status = (({ idWinner }) => {
        if (idWinner) {
          return idWinner === team.id ? 'win' : 'lose';
        }
        return '';
      })(this.match);
      return {
        ...team,
        points,
        status,
        isActive: team.hash === this.currentTeam.hash,
      };
    },
    fetchSearchByName(searchPhrase) {
      return api.get('/search/byname', {
        params: {
          tournamentId: this.id,
          searchPhrase,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 30px 0 15px;
  position: relative;
  @include min-laptop() {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  @include max-laptop() {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.info {
  @include min-laptop() {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
  }

  &-vs {
    display: flex;
    align-items: center;
  }

  &-match {
    display: flex;
    align-items: center;
    @include min-laptop() {
      justify-content: center;
    }
    @include max-laptop() {
      margin: 10px 0;
      width: 100%;
    }
  }
}

.divider {
  padding: 0 12px;
  white-space: nowrap;
}

.points {
  font-weight: bold;

  &.win {
    color: $dark-pastel-green;
  }

  &.lose {
    color: $orangey-red;
  }
}

.date {
  font-size: 13px;
  color: rgba(white, 0.4);
  @include min-laptop() {
    margin-left: 20px;
  }
  @include max-laptop() {
    margin-right: 20px;
  }
}

.team {
  &.tbd {
    color: rgba(white, 0.4);
  }

  &-link.active {
    color: $azure;
  }
}

.round-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  @include min-laptop() {
    margin-left: 20px;
  }
  @include max-laptop() {
    margin-right: 20px;
  }
}
</style>
