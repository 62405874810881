<template>
  <div class="tffa-wrapper">
    <player-next-match
      :id="tournamentId"
      :rounds="rounds"
      system="tffa"
    />
    <div
      ref="fullSizeContainer"
      :class="{ full: isFullSize }"
      class="tffa"
    >
      <div class="ffa-bracket-header">
        <bracket-slider
          :key="bracketKey"
          :left-scroll="leftScroll"
          :rounds="filteredRounds"
          :style="{ fontSize: `${fontSize}px` }"
          @scroll="onBracketScroll"
        >
          <template v-if="$scopedSlots.round" #round="{ round }">
            <slot name="round" :round="round" />
          </template>
        </bracket-slider>
        <div class="tffa-nav">
          <bracket-search
            v-model="activeMatchesId"
            :tournament-id="tournamentId"
          />
          <bracket-zoom v-model="fontSize" @onfullsize="onFullSize" />

          <template v-if="!controlsDisabled">
            <BracketPrintButton @click="printPage" />
            <BracketEmbed :id="tournamentId" />
            <div class="bracket-filters">
              <Select
                v-if="filters.round"
                v-model="filters.round"
                display-value="name"
                option-key="id"
                :options="filterOptions"
                @input="handleFilterInput"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        ref="bracket"
        class="bracket-box"
        @scroll="(event) => onBracketScroll(event.target.scrollLeft)"
      >
        <div class="bracket" :style="{ fontSize: `${fontSize}px` }">
          <div
            v-for="(round, index) in filteredRounds"
            :key="index"
            class="bracket-row"
            :class="{
              zero: index < activeSlideNum,
            }"
          >
            <div class="bracket-col">
              <div
                v-for="(match, key) in round.matches"
                :key="key"
                class="bracket-match team"
                :class="{
                  active: checkActive(match.id),
                }"
              >
                <slot name="match" :match="match">
                  <BaseLink
                    v-if="match.id"
                    class="name"
                    :to="{ name: 'match', params: { id: match.id } }"
                    is-new-tab
                  >
                    {{ match.name }}
                  </BaseLink>
                  <span v-else class="name no-link">{{
                    match.name
                  }}</span>
                  <span class="time">{{
                    $dt(match.tsStartTime, 'dd_MM_HH_mm')
                  }}</span>
                </slot>
                <div v-if="checkActive(match.id)" class="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import BracketSlider from '@components/TournamentComponents/Brackets/BracketUI/BracketSlider.vue';
import BracketZoom from '@components/TournamentComponents/Brackets/BracketUI/BracketZoom.vue';
import BracketSearch from '@components/TournamentComponents/Brackets/BracketUI/BracketSearch.vue';
import PlayerNextMatch from '@components/TournamentComponents/Brackets/BracketUI/PlayerNextMatch.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import Select from '@components/v2/ui/Select.vue';
import BracketPrintButton from '@components/TournamentComponents/Brackets/BracketUI/BracketPrintButton.vue';
import BracketEmbed from '@components/TournamentComponents/Brackets/BracketUI/BracketEmbed.vue';

import { i18n } from '@src/localization/config';

export default {
  components: {
    PlayerNextMatch,
    BracketSearch,
    BracketZoom,
    BracketSlider,
    BaseLink,
    Select,
    BracketPrintButton,
    BracketEmbed,
  },
  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
    rounds: {
      type: Array,
      required: true,
    },
    controlsDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isFullSize: false,
    activeSlideNum: 0,
    activeMatchesId: [],
    fontSize: 16,
    leftScroll: 0,
    selectedRoundId: 0,
    selectedMatchId: 0,
    filters: { round: null },
    bracketKey: 0,
  }),

  computed: {
    matches() {
      let activeRound = this.rounds.find(
        ({ id }) => id === this.selectedRoundId,
      );
      return activeRound?.matches;
    },

    filteredRounds() {
      if (this.filters.round) {
        const roundIndex = this.rounds.findIndex(
          (round) => round.id === this.filters.round.id,
        );

        const slicedRounds = this.rounds.slice(roundIndex);

        return slicedRounds;
      }
      return this.rounds;
    },

    filterOptions() {
      return this.rounds.map((round, index) => ({
        id: round.id,
        name: index ? round.name : i18n.t('tournaments.allBracket'),
      }));
    },
  },

  watch: {
    leftScroll(pos) {
      this.$refs.bracket.scrollLeft = pos;
    },
    fontSize() {
      this.leftScroll -= 1;
    },
    activeMatchesId() {
      this.$nextTick(this.highlightLines);
    },
  },

  mounted() {
    const wrapper = this.$refs.bracket;
    this.hammer = new Hammer(wrapper);
    this.hammer
      .get('pan')
      .set({ direction: Hammer.DIRECTION_HORIZONTAL });

    let pos = 0;
    this.hammer.on('panstart', () => {
      pos = wrapper.scrollLeft;
    });

    this.hammer.on('panleft panright', (ev) => {
      if (Math.abs(ev.deltaY) < 30) {
        wrapper.scrollLeft = pos - ev.deltaX;
      }
    });

    const [firstRound] = this.rounds;
    this.filters.round = firstRound;
  },
  beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
  },
  methods: {
    handleFilterInput() {
      this.leftScroll = 0;
      this.bracketKey += 1;
    },
    onBracketScroll(pos, activeSlideNum) {
      this.leftScroll = pos;
      if (
        activeSlideNum !== undefined &&
        this.activeSlideNum !== activeSlideNum
      ) {
        this.activeSlideNum = activeSlideNum;
      }
    },
    onFullSize(isFull) {
      this.isFullSize = isFull;
    },
    checkActive(matchId) {
      return this.activeMatchesId?.includes(matchId);
    },
    highlightLines() {
      const { bracket } = this.$refs;
      const activeMatches = [
        ...bracket.querySelectorAll('.bracket-match.active'),
      ];

      const activeLines = [].map.call(
        bracket.querySelectorAll('.line'),
        (line) => {
          line.className = 'line';
          return line;
        },
      );
      const activeLinesSize = activeMatches
        .reduce((arr, match, index) => {
          const { top } = match.getBoundingClientRect();
          if (index > 0) {
            const prevMatch = arr[index - 1];
            prevMatch.height = top - prevMatch.top;
          }
          return arr.concat({ top });
        }, [])
        .slice(0, -1);

      activeLinesSize.forEach((size, index) => {
        const line = activeLines[index];
        const height =
          Math.abs(size.height) / Number.parseInt(this.fontSize, 10);
        line.style.height = `${height}em`;
        line.className = `line ${
          size.height >= 0 ? 'totop' : 'tobottom'
        }`;
      });
    },

    getRoundId(roundId) {
      this.selectedRoundId = Number(roundId);
    },

    getMatchInfo(matchId) {
      this.selectedMatchId = Number(matchId);
    },

    setActiveMatchId(activeMatchIndex) {
      this.selectedMatchId = this.matches[activeMatchIndex]?.id;
    },

    printPage() {
      const routeData = this.$router.resolve({
        name: 'bracket-print',
        params: {
          isMainLayout: false,
        },
        props: {
          id: Number(this.tournamentId),
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.tffa {
  &.full {
    overflow: auto;
    background-color: $dark;
    padding-bottom: 40px;

    .tffa-nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.tffa-nav {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  align-items: center;

  @include max-tablet() {
    padding: 0 10px;
    flex-wrap: wrap;
    justify-content: flex-end;

    &-zoom {
      margin-top: 12px;
    }
  }
}

.bracket-search {
  margin-right: auto;

  @include max-tablet() {
    width: 100%;
  }
}

.sticky {
  padding: 1px 0 10px;
  top: $headerHeight;
  background-color: $dark;
}

.bracket {
  display: inline-flex;
  align-items: stretch;

  &-slider {
    margin-top: 16px;
  }

  &-box {
    max-width: 100%;
    overflow: hidden;
    cursor: grab;
    @include min-tablet() {
      margin-left: 30px;
      margin-right: 30px;
    }
    @include max-tablet() {
      margin-left: 10px;
      margin-right: 10px;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &-row {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &.zero {
      overflow: hidden;
      position: relative;
      display: block;
      height: 0;

      & + .bracket-row .bracket-match:before {
        display: none;
      }
    }

    & + & {
      margin-left: em(30px);
    }

    &:first-child {
      .bracket-match:before {
        display: none;
      }
    }

    &:last-child {
      .bracket-match:after {
        display: none;
      }
    }

    &:not(:last-child) {
      .bracket {
        &-col {
          position: relative;

          &:after {
            content: ' ';
            position: absolute;
            top: em(31px);
            bottom: em(31px);
            right: em(-15px);
            width: 1px;
            background: rgba(white, 0.15);
          }
        }
      }
    }
  }

  &-match {
    width: em(200px);
    height: em(62px);
    border-radius: 6px;
    background-color: #252527;
    flex-shrink: 0;
    padding: em(5px) 0 em(5px) em(5px);
    position: relative;
    text-align: center;
    border: 1px solid transparent;

    &.active {
      .name {
        color: $dodger-blue;
      }
    }

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 50%;
      width: em(15px);
      height: 1px;
      background: rgba(white, 0.15);
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }

    & + & {
      margin-top: em(16px);
    }

    .time {
      margin-top: em(8px);
      display: inline-block;
      font-size: em(13px);
      color: rgba(white, 0.43);
    }
  }
}

.name {
  display: block;
  margin: 0 auto;
  white-space: nowrap;
  max-width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;

  &.no-link {
    color: rgba(white, 0.4);
  }

  &:hover {
    text-decoration: none;
  }
}

.line {
  display: none;
  position: absolute;
  right: em(-30px);
  border: 1px solid white;
  width: em(15px);

  &:before {
    content: '';
    width: em(15px);
    right: 100%;
    position: absolute;
    border-top: 1px solid white;
  }

  &.totop {
    display: block;
    top: 50%;
    border-width: 0 0 1px 1px;

    &:before {
      top: 0;
    }
  }

  &.tobottom {
    display: block;
    bottom: 50%;
    border-width: 1px 0 0 1px;

    &:before {
      bottom: 0;
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.ffa-bracket-header {
  position: sticky;
  z-index: 100;
  top: 0;
  padding: 24px 0;
  background: #121415;
}

.bracket-filters {
  max-width: 220px;
  width: 100%;
}

.bracket-controls {
  display: flex;
  gap: 12px;
}

@media print {
  .bracket-match,
  .bracket-row:not(:last-child) .bracket-col:after {
    background-color: #000 !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
</style>
