<template>
  <div class="bracket-search">
    <BaseSelect
      v-model="activeTeamHash"
      class="search"
      list-position="top"
      :placeholder="placeholder || $t('tournaments.bracketSearch')"
      :options="filteredOptions"
      :is-loading="loadingState.search"
      @filter="fetchSearchByName"
    >
      <template v-slot:selected-option="{ option }">
        <div class="box-selected">
          {{ option.historicalName }}
          <span v-if="option.isDiffName" class="name-current"
            >({{ option.currentName }})</span
          >
        </div>
      </template>
      <template v-slot:option="{ option }">
        <div class="box-option">
          <div class="description">
            {{ $t(`${option.description}`) }}
          </div>
          {{ option.historicalName }}
          <span v-if="option.isDiffName" class="name-current"
            >({{ option.currentName }})</span
          >
        </div>
      </template>
    </BaseSelect>

    <icon name="search" />
  </div>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'BracketSearch',
  components: {
    BaseSelect,
    Icon,
  },
  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    activeTeam: '',
    options: [],
    cache: {},
    loadingState: {
      search: false,
    },
  }),
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('tournaments', ['getTournament']),
    filteredOptions() {
      return this.options
        .filter((item) => item?.teamHash !== this.freeSlot.hash)
        .sort((a, b) => {
          if (a.historicalName === b.historicalName) {
            return 0;
          }
          return a.historicalName > b.historicalName ? 1 : -1;
        });
    },
    system() {
      return this.getTournament(this.tournamentId)?.system?.code;
    },
    activeTeamHash: {
      get() {
        return this.activeTeam;
      },
      set(team) {
        this.activeTeam = team;
        const dataVariant =
          {
            team_ffa: 'matchesList',
            rating: 'indexNumber',
          }[this.system] || 'teamHash';

        this.$emit('input', team?.[dataVariant]);
      },
    },
  },
  watch: {
    activeTeamHash() {
      this.$nextTick(() => {
        const box = this.$parent.$refs.bracket;
        if (!this.activeTeamHash || box == null) {
          return;
        }

        box.scrollTo({ left: 0 });

        setTimeout(() => {
          const activeTeam = document.querySelector(
            '.bracket-row .team.active',
          );

          if (!activeTeam) {
            return;
          }

          const activeTeamSize = activeTeam?.getBoundingClientRect();
          const sticky = document.querySelector('.sticky');
          const fullSize = document.querySelector('.full');
          const fromTop = sticky
            ? sticky.getBoundingClientRect().height
            : 0;

          if (fullSize) {
            const fullTop = fullSize ? fullSize.scrollTop : 0;
            fullSize.scrollTo({
              top: activeTeamSize.top + fullTop - fromTop,
            });
          } else {
            window.scrollTo({
              top: activeTeamSize.top + window.pageYOffset - fromTop,
            });
          }
        }, 1000);
      });
    },
  },
  methods: {
    fetchSearchByName: _.debounce(function (searchPhrase) {
      this.options = [];

      if (searchPhrase.length < 3) {
        return false;
      }

      if (this.cache[searchPhrase]) {
        this.options = this.cache[searchPhrase];
        return false;
      }

      this.loadingState.search = true;

      return api
        .get('/search/byname', {
          params: {
            tournamentId: this.tournamentId,
            searchPhrase,
          },
        })
        .then((data) => {
          const {
            TeamTitle = [],
            GameNick = [],
            ProfileNick = [],
          } = data.items;

          const mapData = (arr, description) =>
            arr.map((data) => {
              const isDiffName =
                data.historicalName !== data.currentName;
              return {
                ...data,
                isDiffName,
                description,
              };
            });

          this.cache[searchPhrase] = [].concat(
            mapData(TeamTitle, 'teams.single'),
            mapData(GameNick, 'players.nickname_game_label'),
            mapData(ProfileNick, 'players.nickname_site'),
          );
          this.options = this.cache[searchPhrase];
        })
        .finally(() => {
          this.loadingState.search = false;
        });
    }, 800),
  },
};
</script>

<style lang="scss" scoped>
.bracket-search {
  position: relative;
  width: 336px;
  max-width: 100%;

  .icon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 12px;
    margin-top: -6px;
    color: rgba(white, 0.3);
  }
}

::v-deep {
  .selected,
  .input {
    padding-left: 2em;
  }
}

.box {
  &-selected {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;

    .name-current {
      color: rgba(white, 0.6);
    }
  }

  &-option {
    font-size: 14px;

    .description {
      color: rgba(white, 0.4);
      font-size: 12px;
    }

    .name-current {
      color: rgba(white, 0.6);
    }
  }
}
</style>
