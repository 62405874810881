<template>
  <div class="bracket-zoom">
    <div class="nav-scale">
      <button class="nav-btn scale" @click="changeScale(1)">
        <span>-</span>
      </button>
      <span class="scale-text">{{ scaleText }}</span>
      <button class="nav-btn scale" @click="changeScale(-1)">
        <span>+</span>
      </button>
    </div>
    <button
      v-if="canFullScreen"
      class="nav-btn size"
      @click="toggleFullscreen"
    >
      <Icon
        :name="isFullSize ? 'sizeNormal' : 'sizeFull'"
        :size="16"
      />
    </button>
  </div>
</template>

<script>
import screenfull from 'screenfull';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'BracketZoom',
  components: {
    Icon,
  },
  props: {
    value: {
      type: Number,
      default: 16,
    },
  },
  data: () => ({
    isFullSize: false,
    scale: 0,
    canFullScreen: screenfull.enabled,
  }),
  computed: {
    scaleText() {
      const percent = 100 - this.scale * 10 || 1;
      return `${this.$t('global.size')} ${percent}%`;
    },
  },
  methods: {
    changeScale(way) {
      const scale = this.scale + way;

      if (scale > 10) {
        this.scale = 10;
        return;
      }
      if (scale < -10) {
        this.scale = -10;
        return;
      }

      this.scale = scale;
      this.$emit('input', this.value - way);
    },
    toggleFullscreen() {
      const bracket = this.$parent.$refs.fullSizeContainer;
      if (screenfull.enabled && bracket) {
        this.isFullSize = !this.isFullSize;
        screenfull.toggle(bracket);
        this.$emit('onfullsize', this.isFullSize);
      }
    },
    beforeDestroy() {
      if (screenfull.enabled) {
        screenfull.off('change', this.closeFullSreen);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bracket-zoom {
  display: flex;
}

.nav {
  &-left {
    @include min-tablet() {
      width: 336px;
    }
  }
  &-right {
    display: flex;
    margin-left: auto;

    @include max-tablet() {
      margin-top: 12px;
      justify-content: flex-end;
      font-size: 12px;
    }
  }

  &-scale {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: white;

    .scale-text {
      display: inline-block;
      font-size: 12px;
      margin: 0 8px;
      color: rgba(white, 0.4);
    }

    .nav-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: rgba(white, 0.4);
      line-height: 1;
      color: #f5f5f7;
      border-radius: 6px;

      span {
        font-size: 16px;
      }
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: #454549;
    border: none;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;

    &.size {
      margin-left: 12px;
    }
    &:hover {
      background-color: lighten(#454549, 5%);
    }
    &:active {
      background-color: darken(#454549, 1%);
    }

    .icon {
      fill: #f5f5f7;
    }
  }
}
</style>
