<template>
  <div class="bracket-embed">
    <Button
      type="tertiary"
      size="regular"
      icon-only
      @click="toggleModal"
    >
      <template #slotBefore>
        <Icon name="code" />
      </template>
    </Button>
    <Modal
      v-if="isShowModal"
      type="regular"
      :title="i18n.t('global.bracketCode')"
      @close="toggleModal"
    >
      <p class="bracket-embed__text">
        {{ i18n.t('global.bracketEmbeddedPhrase') }}
      </p>
      <code class="bracket-embed__code">{{ code }}</code>
      <Button
        :text="buttonText"
        type="primary"
        size="big"
        long
        :disabled="isCopied"
        @click="handleCopyButtonClick"
      >
        <template v-if="!isCopied" #slotBefore>
          <Icon name="code" />
        </template>
      </Button>
    </Modal>
  </div>
</template>

<script setup>
import Button from '@components/v2/ui/Button.vue';
import Modal from '@components/v2/Modal.vue';
import Icon from '@components/v2/utils/Icon.vue';

import { defineProps, ref, computed } from 'vue';
import { i18n } from '@src/localization/config';
import { copyToClipboard } from '@utils/copyToClipboard';

const props = defineProps({
  id: {
    type: [String, Number],
    required: true,
  },
});

const isShowModal = ref(false);
const isCopied = ref(false);

const code = computed(() => {
  return `<iframe src="https://pvp.vkplay.ru/tournament/${props.id}/bracket/embedded"
    frameborder="0" allowfullscreen width="100%" height="800"></iframe>`;
});

const buttonText = computed(() => {
  return isCopied.value
    ? i18n.t('global.copied')
    : i18n.t('global.copyCode');
});

const toggleModal = () => {
  isShowModal.value = !isShowModal.value;
};

const handleCopyButtonClick = () => {
  copyToClipboard(code.value);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 2000);
};
</script>

<style lang="scss" scoped>
.bracket-embed__text {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 12px;
}
.bracket-embed__code {
  display: block;
  word-break: break-all;
  padding: 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #f5f5f7;
  background: #454549;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  margin-bottom: 16px;
}
</style>
