<template>
  <div class="se-wrapper">
    <player-next-match :id="tournamentId" :rounds="rounds" />
    <div
      ref="fullSizeContainer"
      :class="{ full: isFullSize }"
      class="se"
    >
      <div class="se-bracket-header">
        <bracket-slider
          :key="bracketKey"
          :left-scroll="leftScroll"
          :rounds="filteredRounds"
          :style="{ fontSize: `${fontSize}px` }"
          @scroll="onBracketScroll"
        >
          <template v-if="$scopedSlots.round" #round="{ round }">
            <slot name="round" :round="round" />
          </template>
        </bracket-slider>
        <div class="se-nav">
          <bracket-search
            v-model="activeTeamHash"
            :tournament-id="tournamentId"
            class="bracket-search"
          />
          <bracket-zoom v-model="fontSize" @onfullsize="onFullSize" />

          <template v-if="!controlsDisabled">
            <BracketPrintButton @click="printPage" />
            <BracketEmbed :id="tournamentId" />
            <div class="bracket-filters">
              <Select
                v-if="filters.round"
                v-model="filters.round"
                display-value="name"
                option-key="id"
                :options="filterOptions"
                @input="handleFilterInput"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        ref="bracket"
        class="bracket-box"
        @scroll="(event) => onBracketScroll(event.target.scrollLeft)"
      >
        <div class="bracket" :style="{ fontSize: `${fontSize}px` }">
          <div
            v-for="(round, index) in filteredRounds"
            :key="index"
            class="bracket-row"
          >
            <template v-if="checkVisibility(index)">
              <div
                v-for="(match, key) in round.matches"
                :key="key"
                class="bracket-col"
              >
                <slot
                  name="match"
                  :match="{ ...match, activeTeamHash }"
                >
                  <match-box
                    :match="match"
                    :order="index ? null : key + 1"
                    :active-hash="activeTeamHash"
                  />
                </slot>
                <div
                  class="line"
                  :class="{ active: activeTeamId === match.idWinner }"
                ></div>

                <template
                  v-if="
                    formatedRounds.thirdPlace.roundNum === index &&
                    key === 1
                  "
                >
                  <div
                    v-for="(currentMatch, idx) in formatedRounds
                      .thirdPlace.match"
                    :key="`${idx}third`"
                    class="third"
                  >
                    <div class="third-title">
                      {{ currentMatch.name }}
                    </div>
                    <slot
                      name="match"
                      :match="{ ...currentMatch, activeTeamHash }"
                    >
                      <match-box
                        :match="currentMatch"
                        :active-hash="activeTeamHash"
                      />
                    </slot>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchBox from '@components/TournamentComponents/Brackets/BracketUI/MatchBox.vue';
import BracketSlider from '@components/TournamentComponents/Brackets/BracketUI/BracketSlider.vue';
import BracketSearch from '@components/TournamentComponents/Brackets/BracketUI/BracketSearch.vue';
import BracketZoom from '@components/TournamentComponents/Brackets/BracketUI/BracketZoom.vue';
import Hammer from 'hammerjs';
import PlayerNextMatch from '@components/TournamentComponents/Brackets/BracketUI/PlayerNextMatch.vue';
import Select from '@components/v2/ui/Select.vue';
import BracketPrintButton from '@components/TournamentComponents/Brackets/BracketUI/BracketPrintButton.vue';
import BracketEmbed from '@components/TournamentComponents/Brackets/BracketUI/BracketEmbed.vue';

import { i18n } from '@src/localization/config';
import { fetchTeam } from '@src/shared/api/team';

export default {
  components: {
    PlayerNextMatch,
    BracketZoom,
    BracketSearch,
    BracketSlider,
    MatchBox,
    Select,
    BracketPrintButton,
    BracketEmbed,
  },
  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
    rounds: {
      type: Array,
      required: true,
    },
    controlsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFullSize: false,
    activeSlideNum: 0,
    maxActiveSlideNum: 6,
    activeTeamHash: null,
    activeTeamId: null,
    fontSize: 16,
    leftScroll: 0,
    filters: {
      round: null,
    },
    bracketKey: 0,
  }),
  computed: {
    filterOptions() {
      return this.formatedRounds.rounds.map((round, index) => ({
        id: round.id,
        name: index
          ? i18n.t('tournaments.top') + ` ${round.matches.length * 2}`
          : i18n.t('tournaments.allBracket'),
      }));
    },

    filteredRounds() {
      if (this.filters.round) {
        const roundIndex = this.formatedRounds.rounds.findIndex(
          (round) => round.id === this.filters.round.id,
        );

        const slicedRounds =
          this.formatedRounds.rounds.slice(roundIndex);

        return slicedRounds;
      }
      return this.formatedRounds.rounds;
    },

    formatedRounds() {
      const rounds = _.cloneDeep(this.rounds);
      const thirdPlace = {
        roundNum: rounds.length - 2,
        match: [],
      };
      const halfFinalRound = rounds[thirdPlace.roundNum] || {};
      if (
        halfFinalRound.matches !== undefined &&
        halfFinalRound.matches.length === 3
      ) {
        thirdPlace.match = [halfFinalRound.matches[2]];
        rounds[thirdPlace.roundNum].matches =
          halfFinalRound.matches.slice(0, -1);
      }
      return {
        rounds,
        thirdPlace,
      };
    },
  },
  watch: {
    leftScroll(pos) {
      this.$refs.bracket.scrollLeft = pos;
    },
    fontSize() {
      this.leftScroll -= 1;
    },
    activeTeamHash: async function (newTeamHash) {
      const { team } = await fetchTeam(newTeamHash);
      this.activeTeamId = team?.id;
    },
  },
  mounted() {
    const wrapper = this.$refs.bracket;
    this.hammer = new Hammer(wrapper);
    this.hammer
      .get('pan')
      .set({ direction: Hammer.DIRECTION_HORIZONTAL });

    let pos = 0;
    this.hammer.on('panstart', () => {
      pos = wrapper.scrollLeft;
    });

    this.hammer.on('panleft panright', (ev) => {
      if (Math.abs(ev.deltaY) < 30) {
        wrapper.scrollLeft = pos - ev.deltaX;
      }
    });

    const [firstRound] = this.rounds;
    this.filters.round = firstRound;
  },
  beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
  },
  methods: {
    handleFilterInput() {
      this.leftScroll = 0;
      this.bracketKey += 1;
    },

    onBracketScroll(pos, activeSlideNum, maxActiveSlideNum) {
      this.leftScroll = pos;
      if (
        activeSlideNum !== undefined &&
        this.activeSlideNum !== activeSlideNum
      ) {
        this.activeSlideNum = activeSlideNum;
      }
      if (
        maxActiveSlideNum !== undefined &&
        this.maxActiveSlideNum !== maxActiveSlideNum
      ) {
        this.maxActiveSlideNum = maxActiveSlideNum;
      }
    },
    onFullSize(isFull) {
      this.isFullSize = isFull;
    },
    checkVisibility(index) {
      return (
        index >= this.activeSlideNum &&
        index <= this.maxActiveSlideNum
      );
    },
    printPage() {
      const routeData = this.$router.resolve({
        name: 'bracket-print',
        params: {
          isMainLayout: false,
        },
        props: {
          id: Number(this.tournamentId),
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.se {
  &.full {
    overflow: auto;
    background-color: $dark;

    .se-nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.se-nav {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 10px;

  @include max-tablet() {
    flex-wrap: wrap;
    justify-content: flex-end;

    .bracket-search {
      width: 100%;
    }
  }
}

.bracket {
  display: inline-flex;
  align-items: stretch;
  padding-bottom: em(150px);

  &-slider {
    margin-top: 16px;
  }

  &-box {
    max-width: 100%;
    overflow: hidden;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    @include min-tablet() {
      margin-right: 30px;
    }
    @include max-tablet() {
      margin-right: 10px;
    }
  }

  &-row {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: em(200px);

    & + & {
      margin-left: em(30px);
    }

    &:first-child {
      @include min-tablet() {
        margin-left: 30px;
      }
      @include max-tablet() {
        margin-left: 10px;
      }
    }
  }

  &-col {
    flex-grow: 1;
    flex-basis: em(80px);
    margin: em(20px) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    opacity: 1;

    .third {
      position: absolute;
      top: calc(50% + 5em);
      width: 100%;
    }
  }

  // bracket-lines
  &-row {
    &:not(:last-child) {
      .line {
        color: rgba(white, 0.15);
        position: absolute;
        width: em(15px);
        height: 50%;
        border-right: 1px solid;
        left: 100%;

        &.active {
          color: rgba(white, 0.5);
        }

        &:after {
          content: ' ';
          position: absolute;
          left: 100%;
          width: em(15px);
          border-top: 1px solid;
          border-color: inherit;
        }
      }

      .bracket {
        &-col {
          position: relative;

          &:nth-child(odd) {
            .line {
              top: 50%;
              border-top: 1px solid;

              &:after {
                top: 100%;
              }
            }
          }

          &:nth-child(even) {
            .line {
              bottom: 50%;
              border-bottom: 1px solid;

              &:after {
                bottom: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.bracket-controls {
  display: flex;
  gap: 12px;
}

.se-bracket-header {
  position: sticky;
  z-index: 100;
  top: 0;
  padding: 24px 0;
  background: #121415;
}

.bracket-search {
  margin-right: auto;
}

.bracket-filters {
  max-width: 220px;
  width: 100%;
}

@media print {
  .line {
    color: #000 !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
</style>
