<template>
  <div class="bracket-match">
    <div v-if="order" class="order">{{ order }}</div>

    <BaseLink
      v-if="team1"
      class="team"
      :class="{
        active: team1.isActive,
        lose: team1.isLooser,
        win: team1.isWinner,
      }"
      :to="{ name: 'team', params: { hash: team1.hash } }"
      is-new-tab
    >
      <span class="name">{{ team1.name }} </span>
      {{ team1.points }}
    </BaseLink>
    <span v-else class="team">TBD</span>

    <BaseLink
      v-if="team1 && team2"
      :class="{ edit: isEdit }"
      class="info"
      :to="{ name: 'match', params: { id: match.id } }"
      is-new-tab
    >
      <Icon name="info" inline />
    </BaseLink>

    <BaseLink
      v-if="team2"
      class="team"
      :class="{
        active: team2.isActive,
        lose: team2.isLooser,
        win: team2.isWinner,
      }"
      :to="{ name: 'team', params: { hash: team2.hash } }"
      is-new-tab
    >
      <span class="name">{{ team2.name }} </span>
      {{ team2.points }}
    </BaseLink>
    <span v-else class="team">TBD</span>

    <span v-if="isEdit" class="match-name">
      {{ $t('matches.single') }} {{ match.numPlaceRound }}
    </span>
    <span class="time">
      {{ $dt(match.tsStartTime, 'dd_MM_HH_mm') }}
    </span>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'MatchBox',
  components: {
    Icon,
    BaseLink,
  },
  props: {
    activeHash: {
      type: String,
      default: '',
    },
    match: {
      type: [Object, Array],
      required: true,
    },
    order: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    team1() {
      return this.formatTeam(1);
    },
    team2() {
      return this.formatTeam(2);
    },
  },
  methods: {
    formatTeam(side) {
      const { idWinner, technicalDefeatHashList = [] } = this.match;
      const team = this.match[`team${side}`];

      if (!team?.id || team.hash === this.freeSlot.hash) {
        return false;
      }

      const isWinner = team.id === idWinner;
      const points = this.match[`pointsTeam${side}`];
      return {
        ...team,
        isWinner,
        hasWinner: idWinner,
        isLooser: idWinner && !isWinner,
        isActive: team.hash === this.activeHash,
        points: technicalDefeatHashList.includes(team.hash)
          ? this.$t('matches.tech_shortDefeat')
          : points,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bracket-match {
  height: em(80px);
  border-radius: 6px;
  background-color: #252527;
  flex-shrink: 0;
  padding: em(5px) 0 em(5px) em(5px);
  position: relative;
  user-select: none;
}

.match-name {
  margin-right: em(10px);
}

.match-name,
.time {
  margin-top: em(8px);
  display: inline-block;
  font-size: em(13px);
  color: rgba(white, 0.43);
}

.info {
  position: absolute;
  padding-right: em(40px);
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 1;
  outline: none;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.edit {
    padding-right: em(35px);
  }

  .icon {
    width: 18px;
    height: 18px;
    color: #cccccc;
  }
}

.order {
  position: absolute;
  line-height: 1;
  top: 50%;
  margin-top: em(-12px);
  background-color: $dark;
  padding: em(5px) em(8px) em(5px) 0;
  border-radius: 0 em(12px) em(12px) 0;
  @include min-tablet() {
    left: em(-30px);
    font-size: em(14px);
  }
  @include max-tablet() {
    left: -0.5em;
    font-size: em(10px);
  }
}

.team {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: em(10px);
  padding-right: em(14px);
  color: #f5f5f7;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  &.lose {
    color: $orangey-red;
  }

  &.win {
    color: $dark-pastel-green;

    .name {
      color: white;
    }
  }

  &.active {
    border-radius: 4px 0 0 4px;
    background-color: rgba($white, 0.1);

    .name {
      color: $azure;
    }
  }
}

.name {
  color: #f5f5f7;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media print {
  .order {
    visibility: hidden;
  }
  .bracket-match {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: #121213 !important;
    color: #cccccc !important;
  }
}
</style>
