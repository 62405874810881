var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bracket-match"},[(_vm.order)?_c('div',{staticClass:"order"},[_vm._v(_vm._s(_vm.order))]):_vm._e(),(_vm.team1)?_c('BaseLink',{staticClass:"team",class:{
      active: _vm.team1.isActive,
      lose: _vm.team1.isLooser,
      win: _vm.team1.isWinner,
    },attrs:{"to":{ name: 'team', params: { hash: _vm.team1.hash } },"is-new-tab":""}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.team1.name)+" ")]),_vm._v(" "+_vm._s(_vm.team1.points)+" ")]):_c('span',{staticClass:"team"},[_vm._v("TBD")]),(_vm.team1 && _vm.team2)?_c('BaseLink',{staticClass:"info",class:{ edit: _vm.isEdit },attrs:{"to":{ name: 'match', params: { id: _vm.match.id } },"is-new-tab":""}},[_c('Icon',{attrs:{"name":"info","inline":""}})],1):_vm._e(),(_vm.team2)?_c('BaseLink',{staticClass:"team",class:{
      active: _vm.team2.isActive,
      lose: _vm.team2.isLooser,
      win: _vm.team2.isWinner,
    },attrs:{"to":{ name: 'team', params: { hash: _vm.team2.hash } },"is-new-tab":""}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.team2.name)+" ")]),_vm._v(" "+_vm._s(_vm.team2.points)+" ")]):_c('span',{staticClass:"team"},[_vm._v("TBD")]),(_vm.isEdit)?_c('span',{staticClass:"match-name"},[_vm._v(" "+_vm._s(_vm.$t('matches.single'))+" "+_vm._s(_vm.match.numPlaceRound)+" ")]):_vm._e(),_c('span',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$dt(_vm.match.tsStartTime, 'dd_MM_HH_mm'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }